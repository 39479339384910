body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(./fonts/Montserrat-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(./fonts/Montserrat-Bold.ttf) format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(./fonts/Montserrat-ExtraBold.ttf) format('truetype');
  font-weight: 900;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-rotating-text-cursor {
  animation: blinking-cursor 0.8s cubic-bezier(0.68, 0.01, 0.01, 0.99) 0s infinite;
}

@keyframes blinking-cursor {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.app {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.subscribe {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 100;
  text-align: center;
  background: linear-gradient(45deg, #0CCBE8 30%, #C4FDC8 90%);
  font-family: Montserrat;
  font-weight: bold;
  font-size: 20px;
  color: white;
  padding: 10px;
}

@media (max-width: 720px) {
  .subscribe {
    width: auto;
    font-size: 16px;
  }
}

.subscribe-email {
  margin: 0 10px 0 10px;
  height: 30px;
  font-size: 16px;
  border-radius: 5px;
}

@media (max-width: 720px) {
  .subscribe-email {
    font-size: 12px;
  }
}

.subscribe-button {
  background-image: linear-gradient(to bottom right, #FF5980, #FBC7B1);
  border-radius: 5px;
  border: 0;
  color: #F6F3F3;
  height: 40px;
  font-size: 16px;
}

.subscribe-error {
  font-size: 16px;
  line-height: 16px;
  font-weight: normal;
  padding: 0;
  margin: 0;
}

.timezones-header {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-image: url("assets/bg-heading.png");
  width: 100%;
  background-repeat: no-repeat;
  background-position: center top;
  margin: 20px;
}

.timezones-header-logo {
  font-family: Montserrat;
  font-weight: bold;
  font-size: 30px;
  color: #333333;
}

.timezones-header-logo > img {
  margin: 0 10px -5px 0
}

.timezones-header-text {
  color: #333333;
  text-align: center;
  font-family: Montserrat;
}

.timezones-header-text.large {
  font-weight: 900;
  font-size: 50px;
  margin-top: 40px
}

@media (max-width: 1024px){
  .timezones-header-text.large {
    font-size: 38px;
  }
}

@media (max-width: 720px){
  .timezones-header-text.large {
    margin-top: 30px;
    font-size: 32px;
  }
}

.timezones-header-text.large > span {
  color: #808080 !important;
  width: 200px !important;
  font-weight: normal;
  display:inline-block !important;
}

.timezones-header-text.small {
  font-size: 35px;
  max-width: 740px;
  margin: 40px 20px 40px 20px
}

@media (max-width: 1024px){
  .timezones-header-text.small {
    font-size: 24px;
  }
}

@media (max-width: 720px){
  .timezones-header-text.small {
    font-size: 20px;
    margin-top: 30px
  }
}

.timezones-footer {
  margin: 80px 0 20px 0
}

.timezones-footer-text {
  color: #808080;
  text-align: center;
  font-family: Montserrat;
}
.timezones-footer-text.large {
  font-size: 18px;
}
.timezones-footer-text.small {
  font-size: 14px;
}

.scheduling-info-container {
  display: flex;
  flex-direction: row;
}

@media (max-width: 1024px){
  .scheduling-info-container {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.scheduling-info-item {
  margin-right:10px;
  align-items: flex-end;
  display: flex;
  flex-direction: row;
}

@media (max-width: 1024px) {
  .scheduling-info-item {
    height: 40px;
  }
}

.scheduling-info-text {
  color: #808080;
  font-weight: bold;
  font-size: 25px;
  font-family: Montserrat;
  margin-right:10px;
  margin-bottom: 10px;
  white-space: nowrap;
}

.scheduling-info-input {
  margin-bottom: 8px;
}

.scheduling-info-input.date {
  width: 170px;
}

.scheduling-info-input.time {
  width: 140px;
}

.scheduling-info-input.duration {
  width: 100px;
}

.MuiSelect-root.MuiSelect-select {
  font-size: 20px;
}

.MuiInputBase-input.MuiInput-input {
  font-size: 20px;
}

.react-add-to-calendar {
  margin: 20px 0 10px 20px !important;
  min-width: 210px !important;
  z-index: 1 !important;
}

@media (max-width: 1024px) {
  .react-add-to-calendar {
    margin-bottom: 0px !important;
  }
}

.react-add-to-calendar__button {
  margin: 10px 0 20px 0 !important;
  background-image: linear-gradient(to bottom right, #FF5980, #FBC7B1) !important;
  background-color: transparent !important;
  border: 0 !important;
  border-radius: 5px !important;
  color: #F6F3F3 !important;
}

.timezones-container {
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: row;
  padding-top: 50px;
  margin-top: 20px;
}

@media (max-width: 1024px) {
  .timezones-container {
    margin-top: 60px;
  }
}

.timezones-button {
  height: 26px;
  bottom: 0;
  background: #FFF6DB;
  border: 1px solid #947519;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 0 0 0 0;
  color: #947519;
}

.timezones-button.remove {
  width: 26px;
  align-self: flex-end;
  font-size: 22px;
  line-height: 22px;
  margin-bottom: -2px;
  padding-bottom: 2px;
}

.timezones-button.add {
  width: auto;
  min-width: 100px;
  margin-top: 30px;
  padding: 3px 10px 5px 10px;
  font-size: 16px;
  line-height: 16px;
}

.timezones-button.date {
  width: auto;
  margin-top: 20px;
  padding: 3px 10px 5px 10px;
  font-size: 16px;
  line-height: 16px;
  position: absolute;
  top:-70px
}

.timezones-button.date.previous {
  left: 0;
}

.timezones-button.date.next {
  right: 0;
}

.timezones-home-icon {
  width: 26px;
  position: relative;
}

.timezones-home-icon > svg {
  position: absolute;
  bottom: 0;
  font-size: 30px;
  color: #808080;
}

.timezones-slider-container {
  position: relative;
  flex-grow: 1;
}

.timezone-slider-handle {
  height: 20px;
  background-image: url("assets/ic-drag-handle.png");
  background-repeat: no-repeat;
  background-position: center;
}

.timezone-slider-time {
  height: 60px;
  position: relative;
}

.timezone-slider-time-start {
  width: 90px;
  left: -90px;
  position: absolute;
  text-align: right;
  padding-right: 10px;
  font-family: Montserrat;
}

.timezone-slider-time-end {
  width: 90px;
  right: -90px;
  position: absolute;
  padding-left: 10px;
  font-family: Montserrat;
}

.timezones-day-container {
  position: absolute;
  width: 100%;
}

.timezone-day {
  display: flex;
  flex-direction: row;
}

.timezones-info-container {
  width: 220px;
}

@media (max-width: 1024px) {
  .timezones-info-container {
    width: 90px;
  }
}

.timezone-info {
  display: flex;
  flex-direction: row;
  height: 60px;
}

@media (max-width: 1024px) {
  .timezone-info {
    flex-wrap: wrap;
  }
}

.timezone-flag {
  height: 30px;
  width: 38px;
  font-size: 35px;
  line-height: 30px;
  align-self: flex-end;
  margin: 0 6px 0 12px;
}

.timezone-name {
  font-size: 20px;
  line-height: 22px;
  align-self: flex-end;
}

@media (max-width: 1024px) {
  .timezone-name {
    font-size: 12px;
    line-height: 12px;
    align-self: flex-end;
  }
}

.timezone-day-sections-container {
  position: relative;
  height: 60px;
  flex-grow: 1;
}

.timezone-day-border {
  width: 1px;
  background-color: darkgray;
  margin: 20px 0 -10px 0;
}

.timezone-day-section {
  height: 30px;
  position: absolute;
  bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.timezone-day-section-text {
  font-size: 18px;
  font-family: Montserrat;
  line-height: 30px;
  position: absolute;
  display: inline-block;
}

.timezone-day-section-text.center {
  margin-left: 50%;
  transform: translateX(-50%);
}

.timezone-day-section-text.left {
  left: 0;
}

.timezone-day-section-text.right {
  right: 0;
}

.section-text-light {
  color: azure;
  padding: 0 5px 0 5px;
}

@media (max-width: 512px) {
  .section-text-light {
    color: transparent;
  }
}

.section-text-dark {
  color: darkgray;
  padding: 0 5px 0 5px;
}

@media (max-width: 512px) {
  .section-text-dark {
    color: transparent;
  }
}

.section-text-bold {
  color: azure;
  font-weight: bold;
  text-transform: uppercase;
}

.timezone-day-section.before-work-hours {
  background: #2E5385
}

.timezone-day-section.before-work-hours.rounded {
  border-radius: 10px 0 0 10px
}

.timezone-day-section.work-hours {
  background: linear-gradient(90deg, #2476E0 -19.28%, #EBF3FF 120.51%)
}

.timezone-day-section.after-work-hours {
  background: #2E5385
}

.timezone-day-section.after-work-hours.rounded {
  border-radius: 0 10px 10px 0;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}
